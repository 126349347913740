<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Peta Sebaran
            <small
              >Hasil Verifikasi Penilaian Mandiri PTSP Pemda, PPB Pemda, dan PPB
              K/L</small
            >
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-5">
            <b-form-select
              v-model="selectedPemerintah"
              :options="optionsPemerintah"
              @change="_loadData"
            >
            </b-form-select>
          </div>
          <div class="col-md-2">
            <div style="justify-content: center">
              <b-form-group
                v-if="selectedPemerintah == 'kl'"
                style="justify-content: center"
              >
                <b-form-radio-group
                  style="justify-content: center"
                  id="btn-radios-2"
                  v-model="selected"
                  :options="optionskl"
                  @change="_getIndikator"
                  button-variant="outline-primary"
                  size="lg"
                  name="radio-btn-outline"
                  buttons
                >
                </b-form-radio-group>
              </b-form-group>
              <b-form-group v-else class="justify-content-center">
                <b-form-radio-group
                  style="justify-content: center"
                  id="btn-radios-2"
                  v-model="selected"
                  :options="options"
                  @change="_getIndikator"
                  button-variant="outline-primary"
                  size="lg"
                  name="radio-btn-outline"
                  buttons
                >
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-5">
            <s-select
              v-model="selectedIndikator"
              :options="optionsIndikator"
              :reduce="(value) => value.variable"
              label="text"
              @input="_loadData"
            >
              <template #option="{ text, tipe }">
                <div style="display: flex; align-items: baseline">
                  <span v-if="tipe == 'kriteria'"
                    ><strong>{{ text }}</strong></span
                  >
                  <span v-else>{{ text }}</span>
                </div>
              </template>
            </s-select>
          </div>
          <!-- <div class="col-md-5" v-if="selectedPemerintah == 'kl'">
                        <b-form-select v-model="selectedIndikator" :options="optionsIndikatorPPBKL" @change="_loadData">
                        </b-form-select>
                    </div>
                    <div class="col-md-5" v-else-if="selectedPemerintah == 'provinsi' || selectedPemerintah == 'kabupaten' || selectedPemerintah == 'kota'">
                        <b-form-select v-if="selected == 'ptsp'" v-model="selectedIndikator"
                            :options="optionsIndikatorPTSP" @change="_loadData">
                        </b-form-select>
                        <b-form-select v-else-if="selected == 'ppb'" v-model="selectedIndikator" :options="optionsIndikatorPPB"
                            @change="_loadData">
                        </b-form-select>
                    </div> -->

          <div class="col-md-12">
            <ejs-maps
              id="maps"
              :zoomSettings="zoomSettings"
              :loaded="_mapsRendering"
              :marker-click="_markerClick"
              :shapeRendering="_mapsShapeRendering"
              :legendSettings="legendSettings"
            >
              <!-- <e-layers>
						<e-layer id="mapsLayer" :layerType= 'layerType'>
							<e-markerSettings>
								<e-markerSetting visible=true :dataSource="dataSource" shape="Balloon" v-on:marker-click="_markerClick"></e-markerSetting>
							</e-markerSettings>
						</e-layer>
					</e-layers> -->
              <e-layers>
                <e-layer
                  :shapeData="shapeDataIndonesia"
                  :markerSettings="markerSettings"
                >
                  <!-- <e-markerSettings>
                                        <e-markerSetting :dataSource="dataSource" shape="Balloon" animation=0
                                            fill="#4caf50"></e-markerSetting>
                                    </e-markerSettings> -->
                </e-layer>
              </e-layers>
            </ejs-maps>
            <div class="my-legend">
              <!-- <div class='legend-title'>The Title or Explanation of your Map</div> -->
              <div class="legend-scale">
                <ul class="legend-labels">
                  <li v-for="data in datalegend" :key="data">
                    <span :style="'background:' + data.colour"></span
                    >{{ data.jawaban }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <!-- Modal -->
      <b-modal
        id="modalDetailKL"
        size="l"
        hide-backdrop
        content-class="shadow"
        centered
      >
        <template #modal-title>
          <span>{{ nilaiJawaban.nama_kl }}</span>
          <!-- <span class="badge" :style="'background-color:'+warna+';color:white'">{{jawaban}}</span> -->
        </template>
        <div>
          <table class="table table-striped">
            <tbody>
              <tr>
                <td style="width: 200px">Total Nilai PPB</td>
                <td>: {{ nilaiJawaban.total_ppb }}</td>
              </tr>
            </tbody>
          </table>

          <div style="margin: auto; width: 50%; text-align: center">
            <H1>Total Akhir</H1>
            <H2>{{ nilaiJawaban.total_akhir }}</H2>
            <h3>
              <span
                class="badge"
                :style="
                  'background-color:' +
                  nilaiJawaban.warna_predikat_akhir +
                  ';color:white'
                "
                >{{ nilaiJawaban.predikat_akhir }}</span
              >
            </h3>
          </div>
        </div>

        <template #modal-footer>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modalDetailKL')"
          >
            Tutup
          </b-button>
        </template>
      </b-modal>
      <b-modal id="modalDetail" hide-backdrop content-class="shadow" centered>
        <template #modal-title>
          <span>{{ nilaiJawaban.daerah }}</span>
          <!-- <span class="badge" :style="'background-color:'+warna+';color:white'">{{jawaban}}</span> -->
        </template>
        <div>
          <table class="table table-striped">
            <tbody>
              <tr>
                <td style="width: 200px">Total Nilai Penilaian Mandiri</td>
                <td>: {{ nilaiJawaban.total_pm }}</td>
              </tr>
              <tr>
                <td style="width: 200px">Total Nilai Asosiasi</td>
                <td>: {{ nilaiJawaban.total_asosiasi }}</td>
              </tr>
              <tr>
                <td style="width: 200px">Total Nilai PPB</td>
                <td>: {{ nilaiJawaban.total_ppb }}</td>
              </tr>
            </tbody>
          </table>

          <div style="margin: auto; width: 50%; text-align: center">
            <H1>Total Akhir</H1>
            <H2>{{ nilaiJawaban.total_akhir }}</H2>
            <h3>
              <span
                class="badge"
                :style="
                  'background-color:' +
                  nilaiJawaban.warna_predikat_akhir +
                  ';color:white'
                "
                >{{ nilaiJawaban.predikat_akhir }}</span
              >
            </h3>
          </div>
        </div>

        <template #modal-footer>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modalDetail')"
          >
            Tutup
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import {
  MapsPlugin,
  Legend,
  DataLabel,
  MapsTooltip,
  Zoom,
  Marker,
  MarkerSettingsModel,
  MarkerSettings,
} from "@syncfusion/ej2-vue-maps";
import { indonesiaMap } from "./indonesia.js";
import vSelect from "vue-select";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

Vue.component("s-select", vSelect);
// import { world_map } from './world-map.js';
Vue.use(MapsPlugin);

export default {
  name: "Home",
  components: {
    //vSelect
    MapsPlugin,
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      dataSource: [
        { Vegetable: "Cabbage", Category: "Leafy and Salad", Id: "item1" },
        { Vegetable: "Spinach", Category: "Leafy and Salad", Id: "item2" },
        { Vegetable: "Wheat grass", Category: "Leafy and Salad", Id: "item3" },
        { Vegetable: "Yarrow", Category: "Leafy and Salad", Id: "item4" },
        { Vegetable: "Pumpkins", Category: "Leafy and Salad", Id: "item5" },
        { Vegetable: "Chickpea", Category: "Beans", Id: "item6" },
        { Vegetable: "Green bean", Category: "Beans", Id: "item7" },
        { Vegetable: "Horse gram", Category: "Beans", Id: "item8" },
        { Vegetable: "Garlic", Category: "Bulb and Stem", Id: "item9" },
        { Vegetable: "Nopal", Category: "Bulb and Stem", Id: "item10" },
        { Vegetable: "Onion", Category: "Bulb and Stem", Id: "item11" },
      ],
      fields: { groupBy: "Category", text: "Vegetable", value: "Id" },
      selectedIndikator: null,
      optionsIndikator: [],
      optionsIndikatorPTSP: [
        { value: null, text: "Silahkan pilih indikator" },
        { value: "pb1a2", text: "Kepatuhan pada NSPK Pusat" },
        { value: "pb1b1", text: "Pendelegasian Wewenang" },
        { value: "pb1d2", text: "Hasil IKM" },
        { value: "pb4b3", text: "Implementasi OSS" },
        { value: "pb5a1", text: "Relasisasi Investasi" },
        { value: "pb5a2", text: "Relasisasi DAK" },
        { value: "pb5b1", text: "Relasisasi Tenaga Kerja" },
        { value: "pb5c1", text: "Fasilitasi kemitraan UMKM" },
        { value: "pb1d4", text: "Adanya monitoring dan evaluasi" },
        {
          value: "sdm_serbpb",
          text: "Kepemilikan sertifikat bidang perizinan berusaha",
        },
        {
          value: "sdm_pengalaman",
          text: "Pengalaman kerja di bidang pelayanan perizinan berusaha",
        },
        { value: "sdm_seross", text: "Kepemilikan sertifikat OSS" },
        { value: "sdm_serbha", text: "Kepemilikan sertifikat bahasa asing" },
        {
          value: "sdm_serppb",
          text: "Kepemilikan sertifikat lain pendukung pelayanan perizinan berusaha",
        },
      ],
      optionsIndikatorPPB: [
        { value: null, text: "Silahkan pilih indikator" },
        {
          value: "p1a5",
          text: "Ketersediaan Perda/Perkada Perizinan Berusaha",
        },
        { value: "p1a6", text: "Kegiatan sosialisasi Perda/Perkada" },
        {
          value: "p2a5",
          text: "Ketersediaan Perda/Perkada terkait RDTR Digital",
        },
        {
          value: "p2b5",
          text: "Ketersediaan Perda/Perkada terkait Izin Lingkungan",
        },
        { value: "p2c5", text: "Ketersediaan Perda/Perkada terkait PBG" },
        { value: "p2d5", text: "Ketersediaan Perda/Perkada terkait SLF" },
        { value: "p2d5", text: "Implementasi OSS" },
        { value: "p3a1", text: "Keberadaan Tim Teknis PPB" },
        { value: "p3a2", text: "Ketersediaan Program Kerja PPB" },
        { value: "p3b1", text: "Koordinasi dengan Kementerian Teknis Lain" },
        { value: "p3b4", text: "Koordinasi dengan OPD Lain" },
        { value: "p3b5", text: "Koordinasi dengan Pemangku Kepentingan Lain" },
        { value: "p3d2", text: "Kenaikan Anggaran" },
        {
          value: "p3d1",
          text: "Ketersediaan Kegiatan Evaluasi PPB Pemda dan Laporan",
        },
      ],
      optionsIndikatorPPBKL: [
        { value: null, text: "Silahkan pilih indikator" },
        { value: "p1a3", text: "Ketersediaan dokumen NSPK" },
        { value: "p1b1", text: "Integrasi dengan OSS" },
        { value: "p3a1", text: "Ketersediaan Unit pelaksana PPB" },
        { value: "p3a2", text: "Ketersediaan Program Kerja PPB" },
        { value: "p3b1", text: "Koordinasi dengan instansi Pusat" },
        { value: "p3b3", text: "Koordinasi dengan Pemda Kabupaten/Kota" },
        {
          value: "p3b4",
          text: "Koordinasi dengan Pemangku Kepentingan lainnya",
        },
        { value: "p3d2", text: "Kenaikan Anggaran" },
        { value: "p3d1", text: "Evaluasi hasil kerja PPB" },
        {
          value: "p1a1",
          text: "Konsultasi pengusulan NSPK dengan Kementerian terkait lainnya",
        },
        {
          value: "p1a2",
          text: "Harmonisasi pengusulan NSPK dengan Kementerian terkait lainnya",
        },
        {
          value: "p1a3",
          text: "Ketersediaan dokumen NSPK terkait penerapan perizinan berusaha",
        },
        { value: "p1a4", text: "Kegiatan sosialisasi NSPK" },
        {
          value: "p1b2",
          text: "Verifikasi teknis dan notifikasi pemenuhan standar dan persyaratan perizinan berusaha berbasis risiko",
        },
        {
          value: "p1c1",
          text: "Kegiatan monitoring dan evaluasi implementasi perizinan berusaha",
        },
        {
          value: "p1c2",
          text: "Laporan kinerja implementasi perizinan berusaha",
        },
      ],
      selectedPemerintah: null,
      optionsPemerintah: [
        { value: null, text: "Silahkan pilih daerah" },
        { value: "provinsi", text: "Provinsi" },
        { value: "kabupaten", text: "Kabupaten" },
        { value: "kota", text: "Kota" },
        { value: "kl", text: "Kementerian/Lembaga" },
      ],
      selected: null,
      options: [
        { text: "PTSP", value: "ptsp" },
        { text: "PPB", value: "ppb" },
      ],
      optionskl: [
        { text: "PTSP", value: "ptsp", disabled: true },
        { text: "PPB", value: "ppb" },
      ],

      markerSettings: [],
      datalegend: [],
      nilaiJawaban: {},
      jawaban: null,
      warna: null,
      // maps property start
      shapeDataIndonesia: indonesiaMap,
      legendSettings: {
        // visible: true,
        type: "Markers",
        removeDuplicateLegend: true,

        // valuePath:'continent'
      },
      shapeDataPath: "State",
      shapePropertyPath: "name",
      layerType: "OSM",
      centerPosition: {
        latitude: -3.2,
        longitude: 118.816666,
      },
      shapeSettings: {
        fill: "#9CBF4E",
        border: { width: 0.5, color: "White" },
      },
      zoomSettings: {
        enable: true,
        enablePanning: true,
        pinchZooming: true,
        // zoomFactor: 5
        // zoomOnClick: true
      },
      centerPosition: {
        latitude: 29.394708,
        longitude: -94.954653,
      },
      dataSource: [],
      mapsElement: {},
      // maps property finish
      checkboxPTSPValue: true,
      checkboxPPBValue: true,
      checkboxKLValue: true,
      data: {
        ptsp: {},
        ptspProvinsi: {},
        ptspKota: {},
        ptspKabupaten: {},
        ppb: {},
        kl: {},
        hipmi: {},
        dataSourceptsp: [],
        dataSourceppb: [],
        dataSourcekl: [],
      },
      modal: {
        daerah: "TEST",
        url_image: "",
        alamat: "TEST",
        nama_organisasi: "TEST",
      },
      dataprofil: [],
      search: "",
      dataIndikator: [],
    };
  },
  computed: {
    filteredList() {
      let filter = this.search.toLowerCase();
      return this.dataprofil.filter((row) => {
        if (
          row.daerah.toLowerCase().indexOf(filter) >= 0 ||
          row.nama_kl.toLowerCase().indexOf(filter) >= 0
        )
          return true;
        return false;
      });
      // return this.dataprofil.filter(post => {
      // 	return post.nama_kl.toLowerCase().includes(this.search.toLowerCase())
      // })
    },
  },
  methods: {
    _loadData() {
      console.log("woy");
      this.markerSettings = [];
      this.datalegend = [];
      axios
        .get(
          this.url +
            "/report/dashboard?tahun=2024&tipe=" +
            this.selected +
            "&daerah=" +
            this.selectedPemerintah +
            "&variable=" +
            this.selectedIndikator,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          var dataresponse = [];
          dataresponse = response.data.data;
          this.datalegend = response.data.legend;
          // this.datalegend = dataresponse.group((item) => item.jawaban);

          for (var i = 0; i < dataresponse.length; i++) {
            var latitudenya = parseFloat(dataresponse[i].latitude);
            //console.log(latitudenya)
            if (isNaN(latitudenya) == false) {
              if (this.selectedPemerintah == "kl") {
                this.markerSettings.push({
                  dataSource: [
                    {
                      latitude: latitudenya,
                      longitude: parseFloat(dataresponse[i].longitude),
                      idptsp: dataresponse[i].idkl,
                    },
                  ],
                  visible: true,
                  height: 20,
                  width: 20,
                  animation: 0,
                  fill: dataresponse[i].colour,
                });
              } else {
                this.markerSettings.push({
                  dataSource: [
                    {
                      latitude: latitudenya,
                      longitude: parseFloat(dataresponse[i].longitude),
                      idptsp: dataresponse[i].idptsp,
                    },
                  ],
                  visible: true,
                  height: 20,
                  width: 20,
                  animation: 0,
                  fill: dataresponse[i].colour,
                });
              }
            }
          }

          // let group = dataresponse.reduce((r, a) => {
          //     console.log("a", a);
          //     console.log('r', r);
          //     r[a.nilai] = [...r[a.nilai] || [], a];
          //     return r;
          // }, {});
          // console.log("group", group);

          // if (group['0'] != undefined) {
          //     this.datalegend.push({
          //         warna: group['0'][0]['colour'],
          //         label: group['0'][0]['jawaban']
          //     })
          // }

          // if (group['10'] != undefined) {
          //     this.datalegend.push({
          //         warna: group['10'][0]['colour'],
          //         label: group['10'][0]['jawaban']
          //     })
          // }

          // if (group['25'] != undefined) {
          //     this.datalegend.push({
          //         warna: group['25'][0]['colour'],
          //         label: group['25'][0]['jawaban']
          //     })
          // }
        })
        .catch((error) => {
          return error;
        });
    },
    _getIndikator() {
      axios
        .get(
          this.url +
            "/report/option?tipe=" +
            this.selected +
            "&daerah=" +
            this.selectedPemerintah,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.optionsIndikator = response.data.data;
        });
    },
    _showModal(idptsp) {
      //this.modal = jawaban;
      if (this.selectedPemerintah == "kl") {
        axios
          .get(this.url + "/total_nilai_kl?filter=idkl,=," + idptsp, {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.nilaiJawaban = response.data.data[0];
            console.log(this.nilaiJawaban);
            this.$bvModal.show("modalDetailKL");
          })
          .catch((error) => {
            return error;
          });
      } else {
        axios
          .get(this.url + "/total_nilai_pemda?filter=idptsp,=," + idptsp, {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.nilaiJawaban = response.data.data[0];
            console.log(this.nilaiJawaban);
            this.$bvModal.show("modalDetail");
          })
          .catch((error) => {
            return error;
          });
      }
    },
    _markerClick(e) {
      console.log(e);
      this.jawaban = e.data.jawaban;
      this.warna = e.data.warna;
      this._showModal(e.data.idptsp);
      // window.open( "https://maps.google.com/?q="+e.latitude+","+e.longitude,'_blank');
    },
    _mapsRendering(e) {
      this.mapsElement = e.maps;
    },
    _mapsShapeRendering(e) {
      // console.log(e);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan", route: "alert" },
      { title: "Peta Sebaran" },
    ]);
    this._loadData();
    // this._getData("ptsp", "Provinsi", 3);
    // this._getData("ptsp", "Kabupaten", 3);
    // this._getData("ptsp", "Kota", 3);
    // this._getData("kl", "", 3);
    // this._getDataCoordinate("ptsp");
    // this._getDataCoordinate("ppb");
    // this._getDataCoordinate("kl");
    // let markerSettings = this.mapsElement.layersCollection[0].markerSettings;
    // markerSettings[0].setAttribute("dataSource",this.dataSource);
    // this.mapsElement.refresh();
  },
  updated() {},
  provide: {
    maps: [Legend, DataLabel, MapsTooltip, Zoom, Marker],
  },
};
</script>
<style type='text/css'>
.my-legend .legend-title {
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 90%;
}

.my-legend .legend-scale ul {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  float: left;
  list-style: none;
}

.my-legend .legend-scale ul li {
  font-size: 80%;
  list-style: none;
  margin-left: 0;
  line-height: 18px;
  margin-bottom: 2px;
}

.my-legend ul.legend-labels li span {
  display: block;
  float: left;
  height: 16px;
  width: 30px;
  margin-right: 5px;
  margin-left: 0;
  border: 1px solid #999;
}

.my-legend .legend-source {
  font-size: 70%;
  color: #999;
  clear: both;
}

.my-legend a {
  color: #777;
}
</style>